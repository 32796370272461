<template>
  <a-spin :spinning="spinning">
    <div ref="liveRecharge">
      <router-header :routerHeaderInfo="routerHeaderInfo" />
      <!-- <a-breadcrumb separator="<">
        <a-breadcrumb-item>
          <router-link :to="{path: 'systemSetting'}">系统配置</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="{path: '/enterprise/systemSettingLive'}">EduSoho直播配置</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>直播充值</a-breadcrumb-item>
      </a-breadcrumb> -->

      <div class="mt-32 mb-72">
        <a-row v-for="(column, row) in rechargeList"
               :key="row"
               :gutter="24">
          <a-col v-for="item in column"
                 :key="item?.hours"
                 :span="8">

            <div class="relative flex flex-col justify-center items-start select-none cursor-pointer"
                 :class="{'border-2 border-admin-primary border-solid': item.checked}"
                 @click="changeRechargeItem(item)">
              <p class="mb24 font-32 fw-500 color-danger">{{ item?.time }} 小时</p>

              <p class="font-16 gray-dark">
                {{ isFirstOrder ? (item?.firstPrice).toLocaleString('en-US')  : (item?.normalPrice).toLocaleString('en-US') }}元
              </p>

              <span v-if="isFirstOrder && item?.firstPrice !== item?.normalPrice"
                    class="absolute top-4 right-4 py-2 px-4 text-color-f border-radius bg-danger">体验专享</span>

              <svg-icon class="absolute top-16 right-24"
                        style="font-size: 112px; color: rgba(49,161,255,0.10);"
                        type="iconchongzhi1" />

              <svg-icon v-if="item.checked"
                        class="checked-icon"
                        type="iconduihao" />
            </div>
          </a-col>
        </a-row>

      </div>

      <div class="save-btn">
        <a-button class="btn"
                  type="primary"
                  @click="handlePayBtn">
          支付
        </a-button>
      </div>
    </div>
  </a-spin>

  <a-modal v-model:visible="wechatPayModalVisible"
           title="订单确认"
           :footer="null"
           width="600px"
           centered
           :bodyStyle="{height: '244px'}"
           :getContainer="() => $refs.liveRecharge"
           :afterClose="handleModalClose">
    <div class="is-flex"
         :style="{height: '100%'}">
      <div class="recharge-modal__info">
        <p class="gray-dark">购买量：<span class="gray-darker">{{ payItem?.time }} 小时</span></p>
        <p class="mt24 gray-dark">应付金额：<span class="color-danger font-16 fw-500">{{ isFirstOrder ? payItem?.firstPrice  : payItem?.normalPrice }}
            元</span>
        </p>
      </div>
      <div class="relative">
        <div v-if="payStatus === 'paying'"
             class="flex justify-center items-center">
          <a-spin />
        </div>

        <div v-else-if="payStatus === 'reload'"
             class="flex absolute top-0 right-76 justify-center items-center bg-color-f5 bg-opacity-80"
             style="width: 120px; height: 120px;">
          <button v-if="payStatus === 'reload'"
                  type="button"
                  @click="reloadQrCode">刷新二维码</button>
        </div>

        <Qrcode v-if="!['paying', 'paid'].includes(payStatus)"
                :width="120"
                :height="120"
                :value="wxPayUrl"
                :dotsOptions="{type: 'square', color: '#000'}"
                :cornersSquareOptions="{ type: 'square', color: '#000000' }"
                imgclass="download-img" />
        <p class="mt8 gray-darker">{{ statusWords[payStatus] }}</p>
      </div>
    </div>
  </a-modal>

</template>

<script>
import _ from "lodash";
import { computed, defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import Qrcode from "qrcode-vue3";
import { apiStore } from "@/shared/service/api-client";

import SvgIcon from "@/components/SvgIcon";

import liveApi from "@/service/api/live";
import platformOrderApi from "@/service/api/platformOrder";
import { useRouter } from "vue-router";
import RouterHeader from "@/components/RouterHeader.vue";

export default defineComponent({
  name: "LiveRecharge",
  props: [],
  emits: [],
  components: {
    // ABreadcrumb: Breadcrumb,
    // ABreadcrumbItem: Breadcrumb.Item,
    Qrcode,

    SvgIcon,
    RouterHeader,
  },
  setup() {
    const spinning = ref(true);
    const router = new useRouter();

    const routerHeaderInfo = [
      {
        path: "/enterprise/systemSetting",
        name: "系统配置",
      },
      {
        path: "/enterprise/systemSettingLive",
        name: "EduSoho直播配置",
      },
      {
        name: "直播充值",
      },
    ];

    // 充值面板
    const rechargeList = ref([]);
    const lastCheckedCharge = ref({});
    const payItem = ref({});

    const isFirstOrder = ref(false);

    const getIsFirstOrder = async () => {
      isFirstOrder.value = await liveApi.isFirstOrder();
    };

    const getPriceConfig = async function () {
      const res = await liveApi.priceConfig();

      rechargeList.value = _.chunk(res, 3);

      rechargeList.value[0][0].checked = true;
      lastCheckedCharge.value = rechargeList.value[0][0];
      payItem.value = rechargeList.value[0][0];
      spinning.value = false;
    };

    const changeRechargeItem = function (item) {
      lastCheckedCharge.value.checked = false;
      item.checked = true;
      lastCheckedCharge.value = item;
      payItem.value = item;
    };

    // 微信支付模态框
    const wechatPayModalVisible = ref(false);
    const wxPayUrl = computed(() => {
      const payAmount = isFirstOrder.value
        ? Number(payItem.value.firstPrice) * 100
        : Number(payItem.value.normalPrice) * 100;

      return (
        "https://" +
        window.location.host +
        "/customer-h5/liveRecharge?corpId=" +
        apiStore.user.corpId +
        "&payAmount=" +
        payAmount +
        "&liveTime=" +
        payItem.value?.time +
        "&orderSn=" +
        orderSn.value
      );
    });
    const orderSn = ref("");

    const handlePayBtn = async () => {
      orderSn.value = await platformOrderApi.generateOrderSn();
      wechatPayModalVisible.value = true;

      pollingVerifyPay();
    };

    const timer = ref("");
    const payStatus = ref("created");

    const statusWords = {
      created: "请用微信扫码支付",
      paying: "订单扫码成功,请在三分钟内完成支付",
      paid: "订单支付成功",
      reload: "二维码已过期请刷新",
    };

    const pollingVerifyPay = () => {
      let count = 0;
      let payCount = 0;

      timer.value = setInterval(async () => {
        if ((count > 60 && !payCount) || payCount > 60) {
          clearInterval(timer.value);

          payStatus.value = "reload";
          return;
        }

        payStatus.value = await platformOrderApi.verifyPay({
          orderSn: orderSn.value,
        });
        count++;

        if (payStatus.value === "paying") {
          payCount++;
        }

        if (payStatus.value === "paid") {
          message.success("支付成功");
          clearInterval(timer.value);
          router.push({ name: "system_setting_live" });
        }
      }, 3000);
    };

    const handleModalClose = () => {
      payStatus.value = "created";
      clearInterval(timer.value);
    };

    const reloadQrCode = async () => {
      orderSn.value = await platformOrderApi.generateOrderSn();
      console.log(orderSn);
      payStatus.value = "created";
      pollingVerifyPay();
    };

    getIsFirstOrder();
    getPriceConfig();

    return {
      spinning,
      isFirstOrder,

      routerHeaderInfo,

      rechargeList,
      changeRechargeItem,
      payItem,

      wechatPayModalVisible,
      wxPayUrl,
      handlePayBtn,

      payStatus,
      statusWords,
      pollingVerifyPay,
      handleModalClose,
      reloadQrCode,
    };
  },
});
</script>

<style lang='less' scoped>
.ant-row {
  &:last-child {
    margin-top: 24px;
  }

  .ant-col > div {
    padding: 0 64px;
    height: 192px;
    .border-radius();
    background-color: fade(@color-primary, 5);

    &:hover {
      .shadow-small(@color-primary);
    }
  }
}

.payment-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  user-select: none;
  cursor: pointer;

  & > div {
    height: 124px;
  }

  &.checked {
    border: 1px solid @color-primary;
  }
}

.payment-icon {
  position: absolute;
  top: 16px;
  right: 24px;
  font-size: 112px;
  color: fade(@color-primary, 10);
}

.checked-icon {
  position: absolute;
  right: -1px;
  bottom: -1px;
  font-size: 60px;
}

.save-btn {
  margin: 0 auto;
}

.is-flex {
  align-items: center;
  & > div {
    flex: 1;
    text-align: center;
  }
}

.recharge-modal {
  &__info {
    justify-items: flex-start;
  }
}
:deep(.download-img) {
  border: 1px solid @border;
}
</style>